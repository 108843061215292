import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSpecialties } from '../../contexts/specialties';
import { LOADING, SUCCESS, treatment_program } from '../../constants/common';
import CheckboxListItem from '../CheckboxListItem';
import { useBasicInfo } from '../../contexts/BasicInfo';
import TextField from '@mui/material/TextField';
import { useAuth } from '../../contexts/Auth';

const SpecialtiesDrawer = ({ onClose, open }) => {
  const { fetchSpecialties, editSpecialties, fetchUserSpecialties, updateSpecialtiesState, specialties } = useSpecialties();
  const { fetchInfo, data: info } = useBasicInfo();
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [otherChecked, setOtherChecked] = useState(false);
  const [otherValue, setOtherValue] = useState('');

  const { account_type } = useAuth();

  const handleClose = () => {
    onClose();
  }

  useEffect(() => {
    if (open) fetchSpecialties({ account_type: account_type === treatment_program ? treatment_program : 'user' });
  }, [open]);

  useEffect(() => {
    if (specialties.createStatus === SUCCESS) {
      updateSpecialtiesState('createStatus', '');
      fetchUserSpecialties({ account_type: account_type === treatment_program ? treatment_program : 'user' });
      if (info?.specialties?.length < 1) fetchInfo();
      handleClose();
    }
  }, [specialties.createStatus, handleClose]);

  useEffect(() => {
    const mappedSpecialties = specialties.list.reduce((acc, item) => {
      if (item.selected) {
        return [...acc, item.id.toString()];
      }
      return acc;
    }, []);

    setSelectedSpecialties(mappedSpecialties);

    // Check if "Other" is in the specialties list
    const otherOption = specialties.list.find((item) => item.id === 'other');
    if (otherOption && otherOption.selected) {
      setOtherChecked(true);
      setOtherValue(otherOption.value || ''); // Populate the custom value if available
    }
  }, [specialties.list]);

  const selectSpecialty = (data) => {
    if (data.id === 'other') {
      setOtherChecked(!otherChecked);
      if (!otherChecked) {
        setOtherValue(''); // Reset if unchecked
      }
      return;
    }

    setSelectedSpecialties((prevState) => {
      const exists = !!prevState.find((item) => +item === data.id);
      if (exists) {
        return prevState.filter((item) => +item !== data.id);
      }
      return [...prevState, data.id];
    });
  }

  const saveSpecialties = () => {
    const payload = {
      account_type: account_type === treatment_program ? treatment_program : 'user',
      specialties: selectedSpecialties,
      // other: otherChecked ? otherValue : null,
    };
    editSpecialties(payload);
  }

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8,
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant="h4" mb={1}>
            Specialties
          </Typography>
          <Typography variant="body1" mb={1}>
            Please select the practice areas you specialize in.
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={2.5}>
            {specialties.fetchStatus === LOADING ? (
              <>
                {[...Array(6)].map((_, index) => (
                  <Grid item xs={6} key={index}>
                    <Skeleton animation="wave" />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {specialties.list.map((item) => (
                  <CheckboxListItem
                    key={item.id}
                    data={item}
                    selectItem={selectSpecialty}
                    selectedItems={selectedSpecialties}
                  />
                ))}
                <CheckboxListItem
                  key="other"
                  data={{ id: 'other', name: 'Other' }}
                  selectItem={selectSpecialty}
                  selectedItems={otherChecked ? ['other'] : []}
                />
                {otherChecked && (
                  <Grid item xs={12} sx={{mt:2}}>
                    <TextField
                      label="Add Specialty"
                      fullWidth
                      value={otherValue}
                      onChange={(e) => setOtherValue(e.target.value)}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              color: 'primary.main',
            }}
            size="large"
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="large"
            fullWidth
            onClick={saveSpecialties}
            loading={specialties.createStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SpecialtiesDrawer;
